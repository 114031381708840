/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "./assets/fonts/gilroy/gilroy.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

* {
  font-family: 'Gilroy';
}

@font-face {
  font-family: 'sx-iconsys';
  src: url('./assets/fonts/xs-iconsys/sx-iconsys.ttf');
}

html, body {
  font-family: Gilroy;
  background: #1BA8B1;
}

.sx-iconsys-icon{
  font-family: 'sx-iconsys' !important;
}


.page{
  margin-left: 274px !important;
}
.content-page {
  padding: 15px 0px;
  margin-top: 160px;
  margin-left: 40px;
  margin-right: 40px;
}

.content-page .row{
  margin: 0 ;
}

.icon-g{
  margin: auto 0 auto 0;
  color: #1BA8B1;
  font-size: 30px;
  font-weight: bold;
}

.display-flex{
  display: flex !important;
}

.button-custom{
  background-color: #EFF1F7 !important;
  color: #1BA8B1 !important;
  border: 1px solid #EFF1F7 !important;
}


/*------------------------*/

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.wrapper.wrapper-full-page {
  height: auto;
  min-height: 100vh;
  padding-bottom: 20px;
}

.full-page:after, .full-page:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.full-page:after {
  z-index: 1;
  opacity: 1;
}

.full-page > .content,
.full-page > .footer {
  position: relative;
  z-index: 9;
}

.full-page > .content {
  min-height: calc(100vh - 70px);
}

.full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.full-page[data-image]:after, .full-page.has-image:after {
  opacity: .7;
}

.full-page[data-color="blue"]:after {
  background-color: rgba(59, 187, 172, 0.7);

}

.full-page[data-color="azure"]:after {
  background-color: rgba(27, 168, 177, .7);

}

.full-page[data-color="green"]:after {
  background-color: rgba(33, 185, 235, .7)
}

.full-page[data-color="orange"]:after {
  background-color: rgba(27, 168, 177, .7);
}

.full-page[data-color="red"]:after {
  background-color: rgba(248, 86, 74, .7)
}

.full-page .footer nav > ul a:not(.btn),
.full-page .footer .copyright,
.full-page .footer .copyright a {
  color: #1BA8B1;
  font-size: 14px;
}

.login-page > .content,
.lock-page > .content {
  padding-top: 15vh;
}

.lock-page .card-lock {
  text-align: center;
  width: 300px;
  margin: 30px auto 0;
  padding: 30px;
  position: absolute;
  left: 50%;
  margin-left: -150px;
  display: block;
}



.mt-10 {
  margin-top: 10px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mt-40 {
  margin-top: 40px!important;
}
.h-100 {
  height: 100%!important;
}
.m-auto {
  margin: auto!important;
}
.p-0 {
  padding: 0!important;
}

/*Estilo personalizado para botones---*/
.btn-custom{
  border-radius: 20px;
  border-width: 2px;
  background-color: #EFF1F7;
  font-weight: 600;
  border-color: #EFF1F7;
  color: #1BA8B1;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-custom:hover {
  background-color: #E0E3EF;
  color: #1BA8B1;
}

